<template>
    <div class="container">
        <input v-model="search" type="text" class="form-control mb-3" placeholder="Search songs...">
        
        <div class="row">
            <div class="col-md-4 order-md-2">
            <div class="button-container" style="display: flex; gap: 10px; flex-wrap: wrap;">
                <button @click="startPresentation" class="btn btn-primary mb-3">Present</button>
                <button @click="clearLocalStorage" class="btn btn-primary mb-3">Clear</button>
                <button @click="printSongs" class="btn btn-primary mb-3">Print Songs</button>
            </div>
            <div class="selected-content">
                <ol>
                <!-- Render song titles by finding each title from its ID -->
                <li v-for="(id, index) in selectedSongIds" :key="id">
                    {{ getSongTitleById(id) }}
                    <button @click="removeFromSelection(index)">Remove</button>
                    <button @click="moveUp(index)" :disabled="index === 0">Up</button>
                    <button @click="moveDown(index)" :disabled="index === selectedSongIds.length - 1">Down</button>
                </li>
                </ol>
            </div>
            </div>
        </div>
            <div class="col-md-8  order-md-1">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Full Content</th>
                            <th>Content without Chords</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="song in filteredSongs" :key="song.id" @click="toggleSelection(song.id)">
                            <td>{{ song.title }}</td>
                            <td>{{ truncate(song.full_content) }}</td>
                            <td>{{ truncate(song.content_without_chords) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <transition name="modal">
            <div class="modal-mask" v-show="showPresentation" ref="modalElement" @keydown.left="prevSlide" @keydown.right="nextSlide">
                <div class="modal-wrapper" @click.self="showPresentation = false">
                    <div class="modal-container">
                        <div class="slide-info">
                            {{ currentSongTitle }}
                        </div>
                        <div class="modal-header">
                            <button type="button" class="modal-close-button" @click="endPresentation">&times;</button>
                        </div>
                        <div class="modal-body" ref="modalBody">
                            <div v-if="currentSlideContent">
                                <pre>{{ currentSlideContent }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            search: '',
            selectedSongIds: [],
            songs: [],
            showPresentation: false,
            currentSlideIndex: 0,
            currentSongSlides: [],
            currentSlideContent: null,
            fullscreen: false,
            xDown: null,
            yDown: null,
        };
    },
    created() {   
        this.loadSongs();
        this.parseLocalStorageOrQueryParams();
        document.addEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
    },
    methods: {
        handleTouchStart(evt) {
        const firstTouch = evt.touches[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    },

    handleTouchMove(evt) {
        if (!this.xDown || !this.yDown) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = this.xDown - xUp;
        let yDiff = this.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { // Detecting swipe direction
            if (xDiff > 0) {
                /* Swipe Left */
                this.nextSlide();
            } else {
                /* Swipe Right */
                this.prevSlide();
            }
        }

        // Reset values
        this.xDown = null;
        this.yDown = null;
    },

        getSongTitleById(id) {
            const song = this.songs.find(song => String(song.id) === String(id));
            return song ? song.title : 'Unknown Song';  // Fallback if song not found
        },

        generateShareableLink() {
            const queryParams = new URLSearchParams({ songs: this.selectedSongIds });
            return `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
        },

        updateUrl() {
            const queryParams = new URLSearchParams({ songs: this.selectedSongIds.join(',') });
            history.replaceState(null, '', `?${queryParams.toString()}`);
        },

        parseLocalStorageOrQueryParams() {
            const queryParams = new URLSearchParams(window.location.search);
            const songIds = queryParams.get('songs');
            if (songIds) {
                this.selectedSongIds = songIds.split(',').map(id => id.trim());  // Assuming IDs are properly URL-decoded by URLSearchParams
            } else {
                const savedSelections = localStorage.getItem('selectedSongs');
                if (savedSelections) {
                    this.selectedSongIds = JSON.parse(savedSelections);
                }
            }
        },
        printSongs() {
            const printWindow = window.open('', '_blank');
            let htmlContent = `
                <html>
                <head>
                    <title>Print Songs</title>
                    <style>
                        body {
                            font-size: 150%; /* Double the font size */
                        }
                        .song {
                            page-break-after: always;
                        }
                        .song:last-child {
                            page-break-after: avoid;
                        }
                        .exit-button {
                            position: fixed;
                            top: 10px;
                            right: 10px;
                            padding: 5px 10px;
                            font-size: 14px;
                            background-color: rgba(0, 0, 0, 0.5);
                            color: white;
                            border: none;
                            border-radius: 3px;
                            cursor: pointer;
                        }
                        .exit-button:hover {
                            background-color: rgba(0, 0, 0, 0.7);
                        }
                    </style>
                </head>
                <body>
            `;

            this.selectedSongIds.forEach(songid => {
                const song = this.songs.find(song => String(song.id) === String(songid));
                if (song) {
                    htmlContent += `
                        <div class="song">
                            <h2>${song.title}</h2>
                            <pre>${song.full_content}</pre>
                        </div>
                    `;
                }
            });

            htmlContent += `
                    <button class="exit-button" onclick="window.close()">Exit</button>
                </body>
                </html>
            `;

            printWindow.document.write(htmlContent);
            printWindow.document.close();
        },

        adjustFontSize() {
        const modalBody = this.$refs.modalBody;
        if (!modalBody) return; // Add this check to ensure modalBody exists

        const pre = modalBody.querySelector('pre');
        if (!pre) return; // Add this check to ensure pre exists

        let fontSize = 20;
        pre.style.fontSize = `${fontSize}px`;

        const adjust = () => {
            const increaseFontSize = () => {
                fontSize++;
                pre.style.fontSize = `${fontSize}px`;
            };

            const decreaseFontSize = () => {
                fontSize--;
                pre.style.fontSize = `${fontSize}px`;
            };

            // Increase font size until the content no longer fits
            while (modalBody.scrollHeight <= modalBody.clientHeight && fontSize < 100) {
                increaseFontSize();
            }

            // If content overflows, reduce font size
            while (modalBody.scrollHeight > modalBody.clientHeight && fontSize > 10) {
                decreaseFontSize();
            }
        };

        requestAnimationFrame(adjust);
        },
        loadSongs() {
            axios.get('/songs.json')
                .then(response => {
                    this.songs = response.data;
                })
                .catch(error => {
                    console.error('Error loading songs:', error);
                });
        },
        toggleSelection(songId) {
            const index = this.selectedSongIds.indexOf(songId);
            if (index === -1) {
                // Add song ID and replace the entire array to trigger reactivity
                this.selectedSongIds = [...this.selectedSongIds, songId];
            } else {
                // Remove song ID and replace the entire array to trigger reactivity
                this.selectedSongIds = [
                    ...this.selectedSongIds.slice(0, index),
                    ...this.selectedSongIds.slice(index + 1)
                ];
            }
        },


        removeFromSelection(index) {
            this.selectedSongIds.splice(index, 1);
            // Ensure update methods are called if not already handled by a watcher
            this.updateLocalStorage();
            this.updateUrl();
        },

        updateLocalStorage() {
            localStorage.setItem('selectedSongs', JSON.stringify(this.selectedSongIds));
        },

        moveUp(index) {
            if (index > 0) {
            [this.selectedSongIds[index - 1], this.selectedSongIds[index]] = [this.selectedSongIds[index], this.selectedSongIds[index - 1]];
            }
        },

        moveDown(index) {
            if (index < this.selectedSongIds.length - 1) {
            [this.selectedSongIds[index + 1], this.selectedSongIds[index]] = [this.selectedSongIds[index], this.selectedSongIds[index + 1]];
            }
        },

        truncate(value, length = 100) {
            return value.length > length ? value.substring(0, length) + '...' : value;
        },
        startPresentation() {
            if (this.selectedSongIds.length === 0) {
                alert("Please select at least one song to present.");
                return;
            }
            document.addEventListener('keydown', this.handleKeyDown);
            this.currentSongIndex = 0;
            this.currentSlideIndex = 0;
            this.prepareSlides();
            this.showPresentation = true;
            this.$nextTick(() => {
                const modalElement = this.$refs.modalElement; // Ensure you have a ref="modalElement" on your modal
                if (modalElement.requestFullscreen) {
                    modalElement.requestFullscreen();
                }
            });
            const modalElement = this.$refs.modalElement;
            modalElement.addEventListener('touchstart', this.handleTouchStart, false);
            modalElement.addEventListener('touchmove', this.handleTouchMove, false);
        },
        endPresentation() {
            this.showPresentation = false;
            
            // Only call exitFullscreen if the document is actually in fullscreen mode
            if (document.fullscreenElement) {
                document.exitFullscreen().catch((err) => {
                    console.error('Error exiting fullscreen:', err);
                });
            }

            // Remove keydown event listener
            document.removeEventListener('keydown', this.handleKeyDown);
            // Remove touch event listeners
            const modalElement = this.$refs.modalElement;
            if (modalElement) {
                modalElement.removeEventListener('touchstart', this.handleTouchStart, false);
                modalElement.removeEventListener('touchmove', this.handleTouchMove, false);
            }
        },
        handleKeyDown(e) {
            if (e.key === 'ArrowLeft') {
                this.prevSlide();
            } else if (e.key === 'ArrowRight') {
                this.nextSlide();
            } else if (e.key === 'Escape' || e.key === 'x') {
                this.endPresentation();
            }
        },
        prepareSlides() {
            this.currentSongSlides = this.selectedSongIds.map(songid => {
                const song = this.songs.find(song => String(song.id) === String(songid));
                // Assuming each slide is separated by double newlines
                return song ? song.content_without_chords.split('\n\n') : [];
            });
            this.currentSongIndex = 0;
            this.currentSlideIndex = 0;
            this.updateCurrentSlideContent();
            this.updateCurrentSongInfo();
        },

        updateCurrentSongInfo() {
            if (this.selectedSongIds.length > 0 && this.currentSongIndex < this.selectedSongIds.length) {
                const song = this.songs.find(song => String(song.id) === String(this.selectedSongIds[this.currentSongIndex]))
                this.currentSongTitle = song.title;
            } else {
                this.currentSongTitle = ''; // Set to empty if out of range
            }
        },
        prevSlide() {
            if (this.currentSongIndex === this.selectedSongIds.length) {
                // If at the end, go back to the last slide of the last song
                this.currentSongIndex = this.selectedSongIds.length - 1;
                this.currentSlideIndex = this.currentSongSlides[this.currentSongIndex].length - 1;
            } else if (this.currentSlideIndex > 0) {
                this.currentSlideIndex--;
            } else if (this.currentSongIndex > 0) {
                this.currentSongIndex--;
                this.currentSlideIndex = this.currentSongSlides[this.currentSongIndex].length - 1;
            }
            // No else clause needed here, as being at the start should not change anything
            this.updateCurrentSlideContent();
            this.updateCurrentSongInfo();
        },
        nextSlide() {
            if (this.currentSongIndex < this.selectedSongIds.length &&
                this.currentSlideIndex < this.currentSongSlides[this.currentSongIndex].length - 1) {
                this.currentSlideIndex++;
            } else if (this.currentSongIndex < this.selectedSongIds.length - 1) {
                this.currentSongIndex++;
                this.currentSlideIndex = 0;
            } else {
                // End of presentation
                this.currentSongIndex = this.selectedSongIds.length; // Set to length to indicate end
                this.currentSlideIndex = 0;
            }
            this.updateCurrentSlideContent();
            this.updateCurrentSongInfo();
        },

        updateCurrentSlideContent() {
            if (this.currentSongIndex < this.selectedSongIds.length &&
                this.currentSongSlides[this.currentSongIndex] &&
                this.currentSongSlides[this.currentSongIndex][this.currentSlideIndex] != null) {
                this.currentSlideContent = this.currentSongSlides[this.currentSongIndex][this.currentSlideIndex];
            } else {
                this.currentSlideContent = ''; // Set to empty to display an empty screen
            }
            this.$nextTick(() => {
                this.adjustFontSize();
            });
        },
        clearLocalStorage() {
            localStorage.removeItem('selectedSongs');
            this.selectedSongIds = [];
        },
        handleFullscreenChange() {
            // Check if fullscreen mode is active; if not, end the presentation
            if (!document.fullscreenElement && this.showPresentation) {
                this.endPresentation();
            }

        }
    },

    computed: {
        filteredSongs() {
            return this.songs.filter(song => song.title.toLowerCase().includes(this.search.toLowerCase()));
        },
        selectedSongsForPresentation() {
            return this.songs.filter(song => this.selectedSongIds.includes(song.id));
        }
    },
    watch: {
        selectedSongIds(newVal) {
            console.log("New IDs: ", newVal);
            localStorage.setItem('selectedSongs', JSON.stringify(newVal));
            this.updateUrl();
        },
        deep: true
    },
    beforeUnmount() {
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
    },
};
</script>

<style>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
  }

  .modal-container {
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 20px; /* Minimal padding */
    overflow: auto;
  }

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  .modal-close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    overflow: hidden;
    width: calc(100% - 40px); /* Adjusted for 20px padding */
    height: calc(100% - 40px); /* Adjusted for 20px padding */
    font-weight: bold; /* Increased font weight */
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
    text-align: left;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .slide-info {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
}

  /* Mobile Friendly Adjustments */
  @media (max-width: 768px) {
    .form-control, .btn {
      font-size: 16px; /* Larger font size for better readability */
      padding: 10px; /* Larger padding for easier interaction */
    }

    .table {
      font-size: 14px; /* Adjust font size for table */
    }

    .modal-mask {
      padding: 10px; /* Add padding around the modal */
    }
  }

  .container .btn-primary {
    background-color: #83A2FF; /* Custom primary button color */
}

.container .form-control {
    border: 1px solid #83A2FF; /* Custom form control border */
}

.container {
    padding-top: 20px;
}

.container .table {
    min-width: 300px; /* Adjust based on your content */
}

.container .table th {
    background-color: #83A2FF;
    color: white;
    font-size: 16px;
    padding: 12px;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.container .table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.container .table tr:hover {
    background-color: #FFD28F;
}

.container .table tr:hover th {
    background-color: #7292e0; /* Darker shade on hover */
}

.container .table tr:hover td {
    transform: scale(1.02); /* Slightly scale up table cells on hover */
}

.container .table td {
    /* Existing styles */
    padding: 10px;
    color: #333;
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease, font-weight 0.3s ease; /* Add font-weight transition */
}

.container .table td:hover {
    cursor: pointer; /* Changes the cursor to a pointer */
    font-weight: bold; /* Makes the text bolder */
    transform: scale(1.02); /* Slightly scale up table cells on hover */
}

@media (max-width: 768px) {
    .container .table {
        font-size: 14px;
    }
    .container .table th, .container .table td {
        padding: 8px;
    }
}
</style>
