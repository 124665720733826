<template>
  <div id="app">
    <song-table/>
  </div>
</template>

<script>
import SongTable from './components/SongTable.vue';

export default {
  components: {
    SongTable
  }
};
</script>
